<template>
  <div class="livreur">
    
    <div class="top">
      <div class="left">
        <div class="marque">
          <span class="">MonResto</span><span class="click">.click</span>
        </div>
        <div v-if="name" class="restaurant">Bienvenue {{ name }}</div>
        <div v-else class="restaurant">Interface livreur</div>
      </div>
      <div class="etat">
        <span v-if="online" class="online">En ligne</span>
        <span v-else class="offline">Perte de connexion...</span>
      </div>
    </div>
    
    <div v-if="load">
      
      <div v-if="!boutique_id" class="login">
        <div class="t">Bienvenue</div>
        <div class="p">Afin de vous authentifier, transmettez ce code à votre gestionnaire</div>
        <div class="code"><span>{{ dual(livreur_key) }}</span></div>
      </div>
      
      <div class="wait" v-else-if="!Object.keys(commandes).length">
        <img src="../../assets/img/illu/livreur_waiting.svg" alt="">
        En attente d'une commande
      </div>
  
      <commande :livreur="livreur_key" class="commande" :info_open="true" v-for="n in commandes" :key="n.id" :data="n" status="sent"></commande>
      
      <alert_command v-if="alert_etat" @click.native="alert_etat = false"></alert_command>
      
<!--      <div class="cliquez">Cliquez pour commencer</div>-->
    </div>
  </div>
</template>

<script>

import NoSleep from "nosleep.js";
let noSleep = new NoSleep();

import alert_command from "@/components/bo/alert_command";
import commande from '@/components/bo/commande'
export default {
  name: "home",
  data() {
    return {
      // login: false,
      commandes: [],
      livreur_key: null,
      boutique_id: null,
      name: null,
      load: false,
      
      alert_etat: false,
      
      old_length: 0,
      
      init_etat: false,
      
      online: false
    }
  },
  mounted() {
    document.addEventListener('click', function enableNoSleep() {
      // document.removeEventListener('click', enableNoSleep, false);

      if(!noSleep.enabled)
        noSleep.enable();
      // noSleep.enable();
    }, false);
  },
  created() {
    if(this.$socket.client.connected) 
      this.online = true
    
      
      if(!localStorage.getItem('livreur_key')) {
        this.livreur_key = this.makekey()
        localStorage.setItem('livreur_key', this.livreur_key)
        this.load = true
      }
      else {
        this.livreur_key = localStorage.getItem('livreur_key')
        this.auth()
      }
      this.$socket.client.emit('join', 'livreur_key_' + this.livreur_key)
    
  },
  sockets: {
    connect() {
      this.online = true
      
      if(this.livreur_key)
        this.$socket.client.emit('join', 'livreur_key_' + this.livreur_key)
      
      this.auth()
    },
    disconnect() {
      this.online = false
    },
    refresh_auth() {
      console.log('refresh_auth')
      this.boutique_id = null
      this.old_length = 0
      this.commandes = 0
      this.name = null
      this.auth()
    },
    refresh() {
      this.sync()
    }
  },
  methods: {
    dual(element) {
      return element.replace(/(.{2})(?=.)/g,"$1 ");
    },
    auth() {
      this.$http.get('/livreur/auth/' + this.livreur_key).then((r) => {
        this.boutique_id = r.data.boutique_id
        this.name = r.data.name
        
        console.log(r)

        this.$socket.client.emit('join', 'livreur_' + r.data.livreur_id)
        this.$socket.client.emit('join', 'livreur_boutique_' + r.data.boutique_id)

        this.sync()
      }).catch( () => this.load = true )
    },
    sync() {
      this.$http.get('/livreur/get/' + this.livreur_key).then((commandes) => {
        this.load = true
        this.commandes = commandes.data
        
        if(this.commandes.length > this.old_length) {
          if(this.init_etat === true)
           this.alert_etat = true
  
          this.old_length = this.commandes.length
        }
        
        this.init_etat = true
      })
    },
    makekey(key = 8) {
      var text = '';
      var possible = '0123456789';

      for (var i = 0; i < key; i++) text += possible.charAt(Math.floor(Math.random() * possible.length));

      return text;
    }
  },
  components: {
    commande,
    alert_command
  }
}
</script>

<style scoped>
  
  .livreur { position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: #000; z-index: 100; overflow-y: auto }
  .livreur .top { padding: 16px; display: flex; flex-direction: column }
  .livreur .top .marque { font-size: 22px }
  .livreur .top .marque span:first-child { color: var(--white-main) }
  .livreur .top .restaurant { color: var(--white-sub); font-weight: 500; font-size: 16px }
  .livreur .top .etat { font-weight: 500; font-size: 16px; padding-top: 3px; }
  .livreur .top .etat .online { color: var(--boVert) }
  .livreur .top .etat .offline { color: var(--boRed) }
  
  .commande { margin-bottom: 16px; padding: 0 8px }
  
  .livreur .wait { position: absolute; top: 0; bottom: 0; left: 0; right: 0; display: flex; align-items: center; justify-content: center; flex-direction: column; color: var(--white-sub); font-size: 20px; }

  .login { position: absolute; top: 0; right: 0; bottom: 0; left: 0; display: flex; align-items: center; justify-content: center; flex-direction: column; text-align: center }
  .login .t { font-size: 40px; color: var(--white-main) }
  .login .p { font-size: 18px; color: var(--white-sub); padding: 16px 26px 36px 26px;  }
  .login .code { font-size: 40px; }
  .login .code span { color: var(--white-main); font-weight: 600; letter-spacing: 1.8px }
  .login .code span:nth-child(1) { margin-right: 8px; }
  
</style>